import { setBlockTracking as _setBlockTracking, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "default-page-container default-page-padding-inline",
  style: {"font-size":"30px","color":"#a80000"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (
      _setBlockTracking(-1),
      _cache[0] = _createElementVNode("div", {
        id: _ctx.idPage,
        ref: "root"
      }, null, 8, ["id"]),
      _setBlockTracking(1),
      _cache[0]
    ),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ScreenSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.errorMessage.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 64))
}