
import { defineComponent } from "vue";
import { ApiHelper, ApiService, GlobalContext, HttpService, PageService, ScreenSpinner } from "table";
import { RepoDto } from "table/dist/services/RepoService/types";

export default defineComponent({
  name: "DefaultPage",
  components: { ScreenSpinner },
  data() {
    return {
      loading: false,
      errorMessage: "",
    };
  },
  computed: {
    backendPage(): string {
      return (this.$route.params as any).backend_page;
    },
    frontendPage(): string {
      return (this.$route.params as any).frontend_page;
    },
    idPage(): string {
      return `default_page_${this.backendPage}`;
    },
    componentUrl(): string {
      return this.backendPage
        ? `/api/pages?name=${this.backendPage}`
        : `/default-pages/${this.frontendPage}`;
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.errorMessage = "";
      this.loading = true;
      try {
        const url = '/api/repo/attr_types';
        const repo = await ApiService.useCache(url, () => HttpService.get<RepoDto>(url).then(x => x.json));
        GlobalContext.setMerge({repo});
        const root = this.$refs.root as HTMLDivElement;
        await PageService.createDefaultPage(
          `#${this.idPage}`,
          this.componentUrl,
          this.$route,
          { root },
        );
      } catch (ex) {
        this.errorMessage = await ApiHelper.getErrorMessage(ex);
      }

      this.loading = false;
    },
  },
});
